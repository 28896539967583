import React from "react"
import Landing from "./Landing";
const Home = () => {
  return (
  	<>
		<Landing />
	</>
  	);
}

export default Home;